import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import "@/assets/style/helper.css";
import { Truncate, TruncateMiddle } from "@/components/shared/filters/truncate.js";
import VueIziToast from "vue-izitoast";
import "izitoast/dist/css/iziToast.css";
import router from './router'
Vue.filter("truncate", Truncate);
Vue.filter("truncate-middle", TruncateMiddle);
Vue.use(Buefy)
Vue.use(VueIziToast);
Vue.config.productionTip = false
Vue.prototype.$eventHub = new Vue();
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
