import Vue from 'vue'
import Router from 'vue-router'
import AttachmentList from './components/attachment-list.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/:uid',
      name: 'home',
      component: AttachmentList,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/:form_uid/field/:field_uid',
      name: 'form-home',
      component: AttachmentList,
      meta: {
        requiresAuth: false
      }
    },
  ]
})
export default router;
