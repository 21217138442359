<template>
  <div class="container is-max-desktop">
    <div class="is-flex">
      <h2 class=" has-text-left ml-15">Attachments ({{ list.length }})</h2>
      <p class="ml-10 is-pointer download-button" v-if="list.length" @click="download_all">Download All</p>
    </div>
    <div class="attachment-list" v-if="list.length && !loading">
      <attachment :item="file" v-for="file in list" :key="file.uid" />
    </div>
    <div class="has-text-centered" v-if="!list.length && !loading">
      No results found
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Attachment from "./attachment.vue";
  export default {
    components: {
      Attachment,
    },
    data() {
      return {
        list: [],
        loading: true
      };
    },
    created() {
      this.getData(this.$route.params.uid);
    },
    methods: {
      getData(id) {
        this.loading = true;
        if(this.$route.query.therm){
          axios
          .get(`${process.env.VUE_APP_THERM_API_HOST}/features/public/issue/${id}/raw_images`)
          .then((res) => {
            console.log(res)
            this.list = res.data.data?.map(item=>({fileName:item.name,url:item.url,thumbnails:item.thumbnails}));
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
        })
        return
      }
        if(this.$route.query.therm){
          axios
          .get(`${process.env.VUE_APP_THERM_API_HOST}/features/public/issue/${id}/raw_images`)
          .then((res) => {
            console.log(res)
            this.list = res.data.data?.map(item=>({fileName:item.name,url:item.url,thumbnails:item.thumbnails}));
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
        })
        return
      }
        if(this.$route.query.form){
          axios
          .get(`${process.env.VUE_APP_SENSEHAWK_API_HOST}/forms/public/${this.$route.params.form_uid}/field/${this.$route.params.field_uid}`)
          .then((res) => {
            console.log(res)
            this.list = res.data?.map(item=>({fileName:item.name,url:item.url,thumbnails:item.thumbnails}));
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
        })
        return
      }
        axios
          .get(`${process.env.VUE_APP_TICKETS_API_HOST}/public/tickets/${id}/attachments`)
          .then((res) => {
            this.list = res.data;
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
          });
      },
      download_all() {
        let files = this.list;
        if (files && files.length) {
          files.forEach((attachment) => (attachment["name"] = attachment.fileName));

          let data = {
            attachments: files.map((attachment) => _.pick(attachment, ["name", "url"])),
            name: `${this.$route.params.uid}-attachments`,
            type: "ticket",
          };

          this.$eventHub.$emit("download_zip", data);
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: `This ticket does not have any attachments!`,
            position: "is-top",
            type: "is-danger",
          });
        }
      },
    },
  };
</script>

<style lang="scss">
  .download-button {
    color: blue;
  }
  .attachment-list {
    // margin: auto 30px;
    justify-items: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 768px) {
    .attachment-list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: 500px) {
    .attachment-list {
      grid-template-columns: repeat(1, 1fr);
    }
  }
</style>
