<template>
  <div class="attachment card" style="box-shadow:none">
    <!-- thumbnail -->
    <div class="attachment--thumbnail">
      <!-- img -->
      <div v-if="item.thumbnails && item.thumbnails.small" class="thumbnail-background">
        <img class="thumbnail" :src="item.thumbnails.small" :alt="item.url" />
      </div>
      <div v-else class="placeholder">
        <img class="is-36x36" :src="require('@/assets/file-type-icons/' + prettyFileIcons.getIcon(item.fileName) + '.svg')" />
      </div>
    </div>
    <div class="card-content">
      <div class="actions">
        <div class="left">
          <img class="is-20x20" :src="require('@/assets/file-type-icons/' + prettyFileIcons.getIcon(item.fileName) + '.svg')" />
        <p class="name">{{ item.fileName | truncate-middle(16)}}</p>
        </div>
        <span @click="download(item)" class="is-pointer">
          <i class="fas download fa-arrow-down"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import prettyFileIcons from "pretty-file-icons";
  export default {
    props: ["item"],
    data() {
      return {
        prettyFileIcons,
      };
    },
    methods :{
      download(item){
        window.open(item.url, "_blank");
      }
    }
  };
</script>

<style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
<style lang="scss">
  .thumbnail-background {
    // background: rgba(23, 30, 72, 1);
    background-color: rgb(243, 242, 242);
  }
  .attachment {
    width: 220px;
    height: 220px;
    border: 1px solid #dadce0;
    margin: 0.8rem;
    &--thumbnail {
      .thumbnail {
        width: 100%;
        height: 165px;
        object-fit: contain;
      }
      .placeholder {
        height: 165px;
        .is-36x36 {
          margin: 3rem auto;
          text-align: center;
          width: 3.6rem;
          height: 3.6rem;
        }
      }
    }
    .card-content {
      padding: 0.5rem 1rem;
      .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 3px;
        .left {
          display: flex;
          align-items: center;

        }
        .is-20x20 {
          width: 1.5rem;
          height: 1.2rem;
        }
        .name {
          font-size: 0.9rem;
          margin-left: 5px;
        }
        .download {
          color: rgb(104, 104, 241);
        }
      }
    }
  }
</style>
